import * as React from "react";
import { useEffect } from "react";
import { Button, Checkbox, CssBaseline, FormControlLabel } from "@mui/material";
import Link from "@mui/material/Link";
import TextField from '../../commons/Input'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import AuthInterface from "../../Interfaces/AuthInterface";
import { showToaster } from "../../commons/common.utils";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// import {GoogleIcon} from '../../assets'

import GoogleLogin from './GoogleLogin'
import _ from "lodash";


export default function LoginForm(props) {
  const {onSuccess} = props;

  const [isPasswordVisible,setIsPasswordVisible] = React.useState(false);
  const [tabValue,setTabValue] = React.useState(0);
  const [isCorporateLogin,setIsCorporateLogin] = React.useState(true);
  const [signUp, setSignUp] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [mailSent, setMailSent] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [hasInvite, setHasInvite] = React.useState(false);
  const [formData,setFormData]= React.useState({});
  const history = useHistory();


  
  useEffect(() => {
    // checkForSession();
  }, []);

  const checkForSession = () => {
    let token = sessionStorage.getItem("PASSPORT_TOKEN");
    AuthInterface.validate({ token })
      .then((success) => {
        history.push("/console");
      })
      .catch((err) => {});
  };


  const handleSubmit = (event) => {
    const submitData = {...formData}
    submitData["userType"] = isCorporateLogin ? "corporate" :"individual"
    let validation = validate(submitData);

    if (signUp) {
      if (mailSent) {
        AuthInterface.register(submitData).then(
          (success) => {
            console.log(success);
            onSuccess && onSuccess();
            sessionStorage.setItem("PASSPORT_TOKEN", success?.data?.token);
            history.push("/console");
          }
        ).catch(e =>{
          showToaster(
            e?.message,{ type:"error" }
          );
        });
      } else {
        if (!validation.valid) {
          setErrorMessage(validation.message);
          return;
        }
        setFormData(submitData);
        setErrorMessage("");
        setMailSent(true);

        AuthInterface.verify(submitData).then((success) => {
          if (success?.data.success) {
            showToaster(
              "Email with verification code has been sent to your e-mail address."
            );
            setMailSent(true);
          } else {
            showToaster(
              "Sorry! We were unable to share you the verification code to the mail address",{
                type:"error"
              }
            );
          }
        }).catch(error =>{
          if(_.get(error,"response.data.error.code") == 11000 ){
            let duplicateEmailMessage = "Sorry! this mail address is already registered with us. Please try again with another email address"
            setErrorMessage(duplicateEmailMessage);
          }
        });
      }
    } else {
      AuthInterface.login(submitData)
        .then((success) => {
          console.log("Login succesful");
          onSuccess && onSuccess();
          sessionStorage.setItem("PASSPORT_TOKEN", success?.data?.token);
          history.push("/console");

        })
        .catch((error) => {
          showToaster("Sorry! Please use valid credentials to login",{
            type:"error"
          });
        });
    }
  };

  const validate = (submitData) => {
    let valid = true;
    let message = "";
    if (
      !String(submitData.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      valid = false;
      message = "Invalid mail ID";
    }
    if (
      !String(submitData.password).match(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
      )
    ) {
      valid = false;
      message =
        "Password should have atleast one numner and one special charecter";
    }
    if (
      (!submitData.companyName || submitData.companyName.length === 0) &&
      submitData.userType === "corporate"
    ) {
      valid = false;
      message = "Please provide the company name";
    }
    if(!termsAccepted){
      valid = false;
      message = "Please accept the term to proceed";
    }
    return {
      valid,
      message,
    };
  };


  const setPasswordVisible = React.useCallback(()=>{
    setIsPasswordVisible(true)
  },[])

  const setPasswordInVisible = React.useCallback(()=>{
    setIsPasswordVisible(false)
  },[])

  const onChange=(e)=>{
    const {name,value} = e.target;
    setFormData((prev)=>({...prev,[name]:value}))
  }

  const handleChange = (e, newValue) =>{
    setTabValue(newValue)
    setIsCorporateLogin(newValue == 0)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function toggleTerms(event) {
    setTermsAccepted(event.target.checked); // Update state when checkbox changes
};

  return (
      <Grid
        container
        component="main"
        sx={{
          display: "flex",
          alignItems: "left",
          justifyContent: "center",
          flexDirection:"column"
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="I represent a Brand" {...a11yProps(0)}/>
        <Tab label="I'm a consumer"{...a11yProps(1)} />
        </Tabs>
      </Box>
        <CssBaseline />
        <Typography component="h1" variant="h5">
          {/* {isCorporateLogin ? "Brand":"Consumer" }{signUp ? " sign up" : " login"} */}
        </Typography>
        <Box
          component="form"
          sx={{ mt: 1,width:"100%" }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            id="email"
            placeholder="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            disabled={mailSent}
            value={formData.email || ""}
            onChange={onChange}
            InputProps={{
              startAdornment:<PersonOutlineOutlinedIcon/>,
              margin:"0 8px"
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            name="password"
            value={formData.password || ""}
            onChange={onChange}
            placeholder="Password"
            type={isPasswordVisible ? "text":"password"}
            id="password"
            autoComplete="current-password"
            disabled={mailSent}
            InputProps={{
              startAdornment:<LockOutlinedIcon marginRight={"8px"}/>,
              endAdornment:isPasswordVisible?<VisibilityOffOutlinedIcon onClick={setPasswordInVisible}/>:<RemoveRedEyeOutlinedIcon onClick={setPasswordVisible}/>,
              margin:"0 8px"
            }}
          />
            {hasInvite && <TextField
            margin="normal"
            fullWidth
            id="referredBy"
             size="small"
             value={formData.referredBy||""}
             onChange={onChange}
            label="Invite code"
            name="referredBy"
            autoComplete="referredBy"
            autoFocus
            disabled={mailSent}
          />}
          {signUp && isCorporateLogin ? (
            <TextField
              margin="normal"
              required
              fullWidth
              size="small"
              value={formData.companyName||""}
              onChange={onChange}
              name="companyName"
              placeholder="Company Name"
              id="companyName"
              disabled={mailSent}
            />
          ) : (
            <div></div>
          )}
          {mailSent && (
            <TextField
              margin="normal"
              required
              fullWidth
              size="small"
              value={formData.verificationCode||""}
              onChange={onChange}
              name="verificationCode"
              label="Verification Code"
              id="verificationCode"
              autoComplete="Verification Code"
            />
          )}
           {signUp && <Box display="flex" alignItems="center">
            {/* Checkbox */}
            <Checkbox
                checked={termsAccepted}
                onChange={toggleTerms}
                inputProps={{ 'aria-label': 'Accept terms and conditions' }}
            />

            {/* Hyperlinked Text */}
            <Typography>
                I accept the{' '}
                <a
                    href="https://app.termly.io/policy-viewer/policy.html?policyUUID=6e889656-2dd6-489a-8743-c1f443f3763d"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline', color: '#1976d2' }}
                >
                    Terms   
                </a>
                &nbsp; and &nbsp;
                <a
                    href="https://app.termly.io/policy-viewer/policy.html?policyUUID=232a4ef7-9d8c-43ad-b1df-0a9f4f882738"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline', color: '#1976d2' }}
                >
                     Privacy policy
                </a>
            </Typography>
        </Box>}
          <Button
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
            }}
            onClick={handleSubmit}
          >
            {signUp ? (mailSent ? "Register" : "Verify") : "Login"}
          </Button>
          <Grid container style={{display:"flex", justifyContent:"space-between"}}>
            <Grid item style={{ cursor: "pointer" }}>
              {signUp ? (
                <Link
                  onClick={() => {
                    setSignUp(false);
                    setFormData({});
                    setMailSent(false)
                  }}
                  variant="body2"
                >
                  {"Already have an account? Login"}
                </Link>
              ) : (
                <Link
                  onClick={() => {
                    setSignUp(true);
                    setFormData({});
                  }}
                  variant="body2"
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              )}
            </Grid>
            {signUp && !hasInvite && !mailSent && <Grid item>
              <Link onClick={()=>{
                setHasInvite(true)
              }} variant="body2">
                Got invite code?
              </Link>
            </Grid>}
          </Grid>
          <Grid container>
            <Grid item xs>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Grid>
          </Grid>
        </Box>
        {/* <Box  marginTop={"20px"} paddingTop={"8px"} borderTop={"2px solid black"}>
         
         <GoogleLogin  
          onSuccess={(element)=>{
            console.log(element)
          }}
          onError={()=>{
            console.logo("error")
          }}

          context={signUp ? "signup":"signin"}
         />
         
        </Box> */}
    </Grid>
  );
}