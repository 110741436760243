
const STATUS ={
  PENDING: "PENDING",
  REGISTERED: "REGISTERED",
  SUBMITTED:"SUBMITTED",
  VERIFIED:"VERIFIED",
  COMPLETED:"COMPLETED",
}

const CATEGORIES ={
  HEALTH: "HEALTH",
}

const TRANSACTION_TYPES = {
  PURCHAE_GOLD: "PURCHAE_GOLD",
  DISTRIBUTE: "DISTRIBUTE",
  REDEEM: "REDEEM"
};

const CONSTANTS = {
  CATEGORIES,
  STATUS,
  TRANSACTION_TYPES
};
export default CONSTANTS