import { toast } from "react-toastify";
import CONSTANTS from "./Constants";
const Web3Utils = require('web3-utils');


const toastType = ["success","error","warning","info"]
const intialToastValues = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme:"light",
  transistion:"Bounce",
  className: "generic-cls",
};

export const showToaster = (text, data) => {
  toast(text, {
    ...intialToastValues,
    ...data,
    hideProgressBar:data?.type ? !Boolean(toastType.includes(data.type)) : true,
  });
};

export const getPurposeIcon = (item) => {
  if (item == CONSTANTS.PURPOSES.SELL) {
    return "fa fa-usd purpose-icon second-grey";
  } else if (item == CONSTANTS.PURPOSES.AUCTION) {
    return "fa fa-gavel purpose-icon second-grey";
  } else if (item == CONSTANTS.PURPOSES.COLLAB) {
    return "fa fa-handshake-o purpose-icon second-grey";
  } else if (item == CONSTANTS.PURPOSES.KEEP) {
    return "fa fa-floppy-o purpose-icon second-grey";
  } else if (item == CONSTANTS.PURPOSES.LICENSE) {
    return "fa fa-id-card-o purpose-icon second-grey";
  }
};

export const getInitialSubString = (string, limit) => {
  if (string && string.length > limit) {
    return string.substring(0, limit) + "... ";
  } else {
    return string || "";
  }
};

export const getShortAddress = (address) => {
  return address
    ? address.substring(0, 3) + " ... " + address.substring(address.length - 3)
    : "";
};

export const fromWei = (weiValue) => {
  if(weiValue)
    return parseFloat(Web3Utils.fromWei(weiValue, 'ether')).toFixed(2)
};

