import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Styles } from "./styles/styles";
import CookieBanner from 'react-cookie-banner';
import ReactGA from 'react-ga';

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize('G-8CY521L6KX'); // Replace with your Tracking ID

ReactGA.pageview(window.location.pathname + window.location.search);
root.render(
  <BrowserRouter>
    <Styles />
    {/* <CookieBanner
      message="Yes, we use cookies. Please accept our cookie policy to access IdeaTribe."
      onAccept={() => {}}
      buttonMessage={"Okay"}
      cookie="user-has-accepted-cookies" /> */}
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
